<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-12">
        <div class="widget">
          <div class="widget-header">
            <div class="row">
              <div class="col-8" />
              <div class="col-4 text-right">
                <div
                  class=""
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <!-- <div
                    class="btn-group mr-2"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="listMode = true"
                    >
                      <i class="icofont icofont-list" />
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click.prevent="listMode = false"
                    >
                      <i class="las la-chart-bar" />
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="widget-content">
            <div
              class="row"
              v-if="listMode"
            >
              <div class="col-12" style="margin-top: 20px;">
                <data-table
                  :data-source="sortedOrders"
                  :headers="headers"
                  table-class="table-striped table-hover"
                />
                  
              </div>
            </div>
        </div>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '../../components/navbar.vue';
import DataTable from '../../components/dataTable/local.vue'
import moment from 'moment'
import { COMPONENT_TYPE, DATE_TYPE, NUMERIC_TYPE, TEXT_TYPE } from '../../components/dataTable/dataType';
const Actions = () => import('../../components/market/order/actions.vue')
export default {
    components: { Navbar, DataTable },
    data(){
        return {
            navbarItems: [
                {
                    libelle: 'Market Place',
                    route: 'market-products'
                },
                {
                    libelle: 'Commandes'
                }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Commandes',
            pageDescription: 'Liste des commandes',
            listMode: true
        }
    },
    computed: {
        ...mapGetters({
            orders: 'market/orders',
            is_super_admin: 'auth/is_super_admin',
        }),
        sortedOrders(){
            return [...this.orders].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        headers(){
            return [
                {label: 'UID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Numero Commande', name: 'orderNumber', type: TEXT_TYPE},
                {label: 'Crée', name: 'createdAt', type: DATE_TYPE, sortable: true},
                {label: 'Destinataire', name: 'user', type: TEXT_TYPE},
                {label: 'Montant', name: 'amount', type: NUMERIC_TYPE, sortable: true},
                {label: 'Etat', name: 'status', type: TEXT_TYPE},
                // {label: 'Reférence de payment', name: 'reference', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }
}
</script>